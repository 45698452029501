<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Mata Pelajaran</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/courses/add')"
        >Tambah</b-button
        v-if="btnAccess"
      >
    </b-alert>

    <div class="card">
        <div class="card-body py-3 px-8">
          <div class="row p-0 justify-content-end">
            <div class="col-md-3">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text squared>
                    <b-icon-search></b-icon-search>
                  </b-input-group-text>
                </template>
                <b-form-input
                  type="text"
                  v-model="filter.name"
                  placeholder="Cari Nama Pelajaran"
                  @keyup="filterByName"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-md-3">
              <b-input-group>
                <treeselect
                  v-model="filter.category_id"
                  :multiple="false"
                  placeholder="Pilih Kategori"
                  :options="categories"
                  @input="filterByCategory"
                />
              </b-input-group>
            </div>
            <div class="col-md-3">
              <b-input-group>
                <treeselect
                  v-model="filter.collection_id"
                  :multiple="false"
                  placeholder="Pilih Koleksi"
                  :options="collections"
                  @input="filterByCollection"
                />
              </b-input-group>
            </div>
            <!-- <div class="col-md-2 offset-md-1 text-right">
              <b-button
                squared
                variant="primary"
                @click="$router.push('/courses/add')"
                >Tambah</b-button
              >
            </div> -->
          </div>
        </div>
      </div>
    <v-app>
      <v-row>
        <v-col
          md="4"
          sm="6"
          xs="12"
          :class="'mt-3'"
          v-for="course in courses"
          :key="course.id"
        >
          <v-hover v-slot="{ hover }">
            <router-link
              :to="'/courses/detail/' + course.id"
              class="router-class"
            >
              <v-card width="300" :class="'class-highlight'">
                <v-img height="200px" :src="course.image">
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        transition-fast-in-fast-out
                        white
                        darken-2
                        v-card--reveal
                        display-3
                        white--text
                      "
                      style="height: 100%"
                    >
                      {{ course.name }}
                    </div>
                  </v-expand-transition>
                  <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-spacer></v-spacer>

                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :style="'z-index: 99'"
                          @click.stop.prevent
                          v-if="btnAccess"
                        >
                          <v-icon :style="'color: #244CA3'"
                            >mdi-dots-vertical</v-icon
                          >
                        </v-btn>
                      </template>

                      <v-card
                        class="mx-auto"
                        max-height="300px"
                        min-width="30%"
                        tile
                      >
                        <v-list dense>
                          <v-list-item-group color="primary" min-width="190">
                            <v-list-item
                              :to="'/courses/edit/' + course.id"
                            >
                              <v-list-item-content> Edit </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="dataDelete(course.id)">
                              <v-list-item-content> Hapus </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-app-bar>
                </v-img>
                <v-card-text :style="card_text_place">
                  <template v-if="hover">
                    <h5 class="card-title" style="color: #244CA3">{{ course.name }}</h5>
                    <span class="d-block mt-1" style="color: #244CA3" v-if="course.grade_name">Tingkat {{course.grade_name}}</span>
                    <span class="d-block mt-1" style="color: #244CA3">{{course.collection_name}}</span>
                    <span class="d-block mt-2" style="color: #244CA3">{{course.category_name}}</span>
                    <p class="mt-8" style="color: #244CA3">
                      {{
                        course.description.length > 180
                          ? course.description.substring(0, 180)
                          : course.description
                      }}
                    </p>
                  </template>
                  <template v-else>
                    <h5 class="card-title">{{ course.name }}</h5>
                    <span class="d-block mt-1" v-if="course.grade_name">Tingkat {{course.grade_name}}</span>
                    <span class="d-block mt-1">{{course.collection_name}}</span>
                    <span class="d-block mt-2">{{course.category_name}}</span>
                    <p class="mt-8">
                      {{
                        course.description.length > 180
                          ? course.description.substring(0, 180)
                          : course.description
                      }}
                    </p>
                  </template>
                </v-card-text>
              </v-card>
            </router-link>
          </v-hover>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      filter:{
        name: "",
        collection_id: "",
        category_id: ""
      },
      mainProps: { blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' },
      courses: [],
      teachers: [],
      card_text_place: "min-height: 220px",
      categories: [],
      collections: [],
      // access
      btnAccess: false
    };
  },
  methods: {
    async pagination() {
      let currentPage = "";
      let perPage = "";
      let filterParams = `&name=${this.filter.name}&category_id=${this.filter.category_id}&collection_id=${this.filter.collection_id}`
      let response = await module.paginate(
        `api/courses`,
        `?page=${currentPage}&page_size=${perPage}${filterParams}`
      );

      this.courses = response.data;
    },
    async getCollectionOption() {
      let response = await module.setTreeSelect("api/collections");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.collections = response.data;
        this.collections.unshift({
          label: "Pilih Koleksi",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getCategoryOption() {
      let response = await module.setTreeSelect("api/categories/select");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.categories = response.data;
        this.categories.unshift({
          label: "Pilih Kategori",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByName() {
      this.pagination();
    },
    async filterByCategory(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.category_id = await "";
        this.pagination();
      } else {
        this.filter.category_id = await evt;
        this.pagination();
      }
    },
    async filterByCollection(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.collection_id = await "";
        this.pagination();
      } else {
        this.filter.collection_id = await evt;
        this.pagination();
      }
    },
    
    async dataDelete(id) {
      // Delete Data
      let result = await module.delete(`api/courses/${id}`);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        for(let course_code = 7000; course_code <= 7011; course_code++){
          if (access_right[a] == course_code) {
            this.btnAccess = true;
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mata Pelajaran" }]);
    this.pagination();
    this.getCategoryOption();
    this.getCollectionOption();
    this.checkAccess()
  },
};
</script>

<style scoped>
.card-title {
  color: black;
}

.card-text {
  color: rgb(94, 92, 92) !important;
}

.teacher-name {
  color: rgb(94, 92, 92);
  position: absolute;
  bottom: 25px;
}
.teacher-photo {
  color: rgb(94, 92, 92);
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.classroom-room {
  position: absolute;
  bottom: 34px;
}

.class-highlight:hover {
  color: #244CA3 !important;
}

.router-class:hover {
  color: #244CA3 !important;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.image-input-wrapper {
  width: 40px !important;
  height: 40px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>